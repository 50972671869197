/* eslint-disable react-hooks/exhaustive-deps */
import MenuIcon from "@mui/icons-material/Menu";
import { Slide } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getNavItems } from "application/navigation";
import { ReactComponent as ArrowDownSimpleSVG } from "assets/svg/arrow-down-simple.svg";
import clsx from "clsx";
import React, { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import * as paths from "ui/Router/paths";
import { Logo, LogoNoText, Navigation } from "ui/components";
import { useAuthorized, useSyndicationLayout } from "ui/hooks";
import AuthorizedBlock from "./AuthorizedBlock/AuthorizedBlock";
import { NavigationItems } from "./HeaderNav/HeaderNav";
import UnauthorizedBlock from "./UnauthorizedBlock/UnauthorizedBlock";
import styles from "./styles.module.scss";
import { useFeatureFlag } from "ui/hooks/useFeatureFlag";
// import { HelpOutline } from "@mui/icons-material";
// import { Link } from "react-router-dom";

interface ToggleNavButtonProps {
  open: boolean;
  onClick: () => void;
  light?: boolean;
}

interface HeaderProps {
  hide?: boolean;
  transparent?: boolean;
}

const Header: FC<HeaderProps> = ({ hide, transparent: defaultTransparent }) => {
  const location = useLocation();
  const theme = useTheme();
  const breakpointMdOrMore = useMediaQuery(theme.breakpoints.up("md"));
  const smallScreen = useMediaQuery("(max-width:428px)");
  const { authorized } = useAuthorized();
  const [open, setOpen] = React.useState(false);
  const headerRef = React.useRef<HTMLDivElement | null>(null);
  const { isSyndicate } = useSyndicationLayout();
  const isSignUp = useMemo(() => {
    return location.pathname.startsWith("/" + paths.signUpPath);
  }, [location]);
  const { isDealsOn } = useFeatureFlag();

  const transparent = useMemo(() => {
    return !isSyndicate && !open && defaultTransparent;
  }, [open, defaultTransparent]);

  const toggleNavigation = () => {
    setOpen(!open);
  };

  const closeDrawer = () => {
    if (open) {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    closeDrawer();
  }, [location]);

  if (hide) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={closeDrawer}>
      <div className="relative">
        <AppBar
          position="relative"
          id="m9-header"
          ref={headerRef}
          classes={{
            root: clsx(
              "transition-colors ease-in-out top-0 duration-200 border-0 border-b border-opacity-10 border-white z-40 shadow-[0px_1px_0px_0px_rgba(0,_0,_0,_0.10)]",
              {
                "bg-transparent": transparent,
                "bg-white": !transparent,
              }
            ),
          }}
        >
          <Toolbar className="p-0">
            <div className="flex items-center m9-container !py-0">
              {!isSignUp && !breakpointMdOrMore && (
                <IconButton
                  size="large"
                  edge="start"
                  color="primary"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={toggleNavigation}
                >
                  <MenuIcon
                    className={clsx({
                      "text-white": transparent,
                    })}
                  />
                </IconButton>
              )}
              <Stack
                direction="row"
                spacing={2.5}
                divider={
                  isSyndicate ? null : (
                    <div className="py-1.5">
                      <Divider
                        orientation="vertical"
                        className={clsx(transparent && "border-white")}
                      />
                    </div>
                  )
                }
                className="grow"
              >
                {smallScreen || authorized || isSyndicate ? (
                  <LogoNoText light={transparent} />
                ) : (
                  <Logo light={transparent} />
                )}
                {!isSignUp && breakpointMdOrMore && (
                  <>
                    {authorized ? (
                      <ToggleNavButton
                        light={transparent}
                        onClick={toggleNavigation}
                        open={open}
                      />
                    ) : null}
                    {isDealsOn && (
                      <NavigationItems
                        isAuthorized={authorized}
                        isSyndicate={isSyndicate}
                        light={transparent}
                      />
                    )}
                  </>
                )}
              </Stack>
              <div className="grow-0 md:grow" />
              <div className="flex items-center">
                {/* <Link to={"/" + paths.helpCenter} className="mr-3 mt-1">
                  <HelpOutline className="text-gray-500 hover:text-black" />
                </Link> */}
                {authorized ? (
                  <AuthorizedBlock
                    hideActions={isSignUp}
                    light={transparent}
                    headerRef={headerRef}
                  />
                ) : (
                  <UnauthorizedBlock light={transparent} />
                )}
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Slide
          style={{ zIndex: 30, position: "relative" }}
          in={open}
          unmountOnExit
          direction="down"
        >
          <div className="bg-white w-screen">
            <div className="!p-8 !sm:p-12 m9-container">
              <Navigation items={getNavItems(authorized)} />
            </div>
          </div>
        </Slide>
      </div>
    </ClickAwayListener>
  );
};

const getNavigateLabel = (pathname: string): string => {
  if (pathname.startsWith("/deal")) {
    return "Deals";
  }

  if (pathname.startsWith("/project")) {
    return "Projects";
  }

  if (pathname.startsWith("/portfolio")) {
    return "Portfolio";
  }

  return "Menu";
};

const ToggleNavButton: React.FC<ToggleNavButtonProps> = ({
  open,
  onClick,
  light,
}) => {
  const { pathname } = useLocation();
  const navigateLabel = getNavigateLabel(pathname);

  return (
    <Button
      onClick={onClick}
      className={clsx("hover:bg-transparent px-2 group", {
        "text-white": light,
      })}
      size="small"
    >
      {navigateLabel}
      <span
        className={clsx("leading-[0] ml-1 duration-300 transform-gpu", {
          [styles.rotateIcon]: open,
        })}
      >
        <ArrowDownSimpleSVG
          className={clsx({
            "fill-white": light,
          })}
        />
      </span>
    </Button>
  );
};

export default Header;
