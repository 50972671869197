import { FeedbackError } from "application/errors";
import clsx from "clsx";
import {
  ChangeEventHandler,
  FC,
  HTMLProps,
  ReactElement,
  Ref,
  useCallback,
} from "react";
import MarkdownEditor, { Plugins } from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import dealService from "services/deal.service";
import { MarkdownInputInsertLogoPlugin } from "./MarkdownInput.plugins";
import { MarkdownInputViewer } from "./MarkdownInput.viewer";

MarkdownEditor.use(Plugins.TabInsert, {
  tabMapValue: 2,
});

MarkdownEditor.use(MarkdownInputInsertLogoPlugin);

MarkdownEditor.unuse(Plugins.FullScreen);

export interface MarkdownInputInsertTextPluginProps {
  label: ReactElement;
  options: Option[];
}

export interface MarkdownInputProps
  extends Pick<
    HTMLProps<HTMLTextAreaElement>,
    | "onBlur"
    | "onKeyDown"
    | "onKeyPress"
    | "onKeyUp"
    | "onChange"
    | "placeholder"
    | "className"
    | "readOnly"
    | "disabled"
    | "style"
  > {
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  value: string;
  inputRef?: Ref<HTMLTextAreaElement>;
  viewerReplacer?: (raw: string) => string;
  config?: { insertText?: MarkdownInputInsertTextPluginProps };
  defaultTextColor?: string;
}

export const LegacyMarkdownInput: FC<MarkdownInputProps> = ({
  onChange,
  className,
  value,
  inputRef,
  config,
  viewerReplacer = (value) => value,
  defaultTextColor,
  ...props
}) => {
  const uploadImage = useCallback(async (file: File) => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append("File", file);

        const { data } = await dealService.uploadDealDescriptionImage(formData);

        return data.link;
      }
    } catch (error) {
      throw new FeedbackError("It was not possible to upload the images");
    }
  }, []);

  return (
    <MarkdownEditor
      renderHTML={(text) => (
        <MarkdownInputViewer defaultTextColor={defaultTextColor}>
          {viewerReplacer(text)}
        </MarkdownInputViewer>
      )}
      onChangeTrigger="both"
      htmlClass="remove-classes"
      markdownClass="font-sans !text-[1rem] !leading-[1.2]"
      onImageUpload={uploadImage}
      onChange={(currentValue) => {
        onChange({ target: { value: currentValue?.text || "" } } as any);
      }}
      config={config}
      className={clsx("h-full rounded-lg overflow-auto", className)}
      value={value}
      ref={(editor) => {
        const mdReference = editor?.getMdElement();

        if (typeof inputRef === "function" && mdReference) {
          inputRef(mdReference);
        }
      }}
      {...props}
    />
  );
};
