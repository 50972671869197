import { useTheme } from "@mui/material";
import { PredefinedRoles } from "ui/pages/DealDataRoom/DealDataRoom.types";

export const useChipStyles = () => {
  const { customColors, palette } = useTheme();

  const getChipsStyles = (key?: string) => {
    const styles: Record<PredefinedRoles | string, object> = {
      [PredefinedRoles.ALL]: {
        backgroundColor: customColors.secondary[200],
        color: customColors.gray[500],
        border: `1px solid ${customColors.secondary[300]}`,
        "&:hover": {
          backgroundColor: customColors.secondary[300],
        },
      },
      [PredefinedRoles.MANAGER]: {
        backgroundColor: customColors.semantic.blue150,
        color: palette.primary.main,
        border: `1px solid ${customColors.complementary[300]}`,
        "&:hover": {
          backgroundColor: customColors.complementary[200],
        },
      },
      [PredefinedRoles.USER]: {
        backgroundColor: customColors.primary[0],
        color: palette.primary.main,
        "&:hover": {
          backgroundColor: customColors.primary[100],
        },
      },
      [PredefinedRoles.INVESTOR]: {
        backgroundColor: customColors.secondary[100],
        color: customColors.gray[500],
        border: `1px solid ${customColors.secondary[200]}`,
        "&:hover": {
          backgroundColor: customColors.secondary[0],
        },
      },
      [PredefinedRoles.ADMIN]: {
        backgroundColor: customColors.danger[100],
        color: customColors.danger[600],
        border: `1px solid ${customColors.danger[200]}`,
        "&:hover": {
          backgroundColor: customColors.danger[200],
          borderColor: palette.error.main,
        },
      },
    };
    if (key && styles[key]) {
      return styles[key];
    } else {
      return {
        backgroundColor: "#fff",
        color: customColors.gray[500],
        border: `1px solid ${customColors.gray[200]}`,
        "&:hover": {
          borderColor: customColors.gray[300],
          backgroundColor: customColors.gray[150],
        },
      };
    }
  };

  const getDeleteIconStyles = (key?: string) => {
    const styles: Record<string, object> = {
      [PredefinedRoles.ALL]: {
        "&.MuiChip-deleteIcon": { color: customColors.gray[500] },
      },
      [PredefinedRoles.MANAGER]: {
        "&.MuiChip-deleteIcon": { color: customColors.primary[600] },
      },
      [PredefinedRoles.USER]: {
        "&.MuiChip-deleteIcon": { color: palette.primary.main },
      },
      [PredefinedRoles.ADMIN]: {
        "&.MuiChip-deleteIcon": { color: customColors.danger[600] },
      },
    };
    if (key && styles[key]) {
      return styles[key];
    }
  };

  return {
    getChipsStyles,
    getDeleteIconStyles,
  };
};
