import { Check, Close, ContentCopy } from "@mui/icons-material";
import { Avatar, Button, IconButton, Typography } from "@mui/material";
import FunctionsIcon from "@mui/icons-material/Functions";
import { FeedbackError } from "application/errors";
import {
  cleanAssistantMessage,
  clsxm,
  copyTextToClipboard,
  delayAsync,
  getNameInitials,
} from "application/utils";
import clsx from "clsx";
import { isFunction, uniqBy } from "lodash";
import Markdown from "markdown-to-jsx";
import { useSnackbar } from "notistack";
import { generatePath, useNavigate } from "react-router-dom";
import e9Logo from "assets/svg/logo-e9-dark-no-text.svg";
import {
  FC,
  HTMLProps,
  KeyboardEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactSyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNightEighties } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Key } from "ts-key-enum";
import {
  IAssistantMessage,
  IMessageAnnotation,
  IFunctionMetadata,
} from "types/assistant.service";
import { useDealAlias, useErrorHandler, useSyndicationLayout } from "ui/hooks";
import { CopyButton } from "../Button";
import { MenuButton } from "../MenuButton";
import { ConfirmationModal } from "../Modal";
import styles from "./AssistantChat.module.scss";
import * as paths from "ui/Router/paths";
import { defaultBrandingColors } from "domain/common";
import { ResourceType } from "types/resource.service";

export const MessageLoader = () => {
  return (
    <div className={styles.Loader}>
      <div className={styles.DotFlashing}></div>
    </div>
  );
};

interface MarkdownSyntaxHighlightProps {
  className: string;
  children: string;
}

interface FunctionCitationProps {
  functionCitation: IFunctionMetadata;
}

const FunctionCitation: FC<FunctionCitationProps> = ({ functionCitation }) => (
  <div className="flex flex-row justify-center items-center px-2 py-1 mt-2 mr-4 rounded-md border border-solid border-gray-200">
    <FunctionsIcon className="h-5" />
    <Typography
      variant="caption"
      className="ml-1"
      title={functionCitation.functionName}
    >
      {`Source: ${functionCitation.source}`}
    </Typography>
  </div>
);

type FileCitationItem = Pick<
  IMessageAnnotation,
  "fileId" | "fileKey" | "fileName"
>;

interface FileCitationProps {
  fileCitation: FileCitationItem;
  index: number;
  type?: ResourceType;
}

const FileCitation: FC<FileCitationProps> = ({ fileCitation, index, type }) => {
  const navigate = useNavigate();
  const dealAlias = useDealAlias();

  function getDataroomPath(fileCitation: FileCitationItem): string | undefined {
    if (!fileCitation.fileKey || !fileCitation.fileName) {
      return undefined;
    }

    const folder = fileCitation.fileKey.replace(fileCitation.fileName, "");
    switch (type) {
      case "Project":
        return generatePath(paths.projectDataroomVDRDirectoriesFolder, {
          alias: dealAlias,
          folder: folder,
        });
      case "fund":
        return generatePath(paths.dealDataroomDirectoriesFolder, {
          alias: dealAlias,
          folder: folder,
        });
      default:
        return undefined;
    }
  }

  function onFileCitationClick(fileCitation: FileCitationItem) {
    const dataroomPath = getDataroomPath(fileCitation);
    if (dataroomPath) {
      navigate("/" + dataroomPath);
    }
  }
  return (
    <Button
      variant="text"
      color="primary"
      onClick={() => onFileCitationClick(fileCitation)}
      disabled={!fileCitation.fileKey || !type}
      className="h-6 px-1"
    >
      <Typography variant="caption" className="font-bold" color="primary">
        {`[${index + 1}:${fileCitation.fileName || "Deleted"}]`}
      </Typography>
    </Button>
  );
};

export const MarkdownSyntaxHighlight: FC<MarkdownSyntaxHighlightProps> = ({
  children,
  className,
}) => {
  const language = className?.split("-")?.pop() || "";
  const [displayCopyFeedback, setDisplayCopyFeedback] = useState(false);

  const handleClick = useCallback(async () => {
    await copyTextToClipboard(children);
    setDisplayCopyFeedback(true);

    await delayAsync(5000);

    setDisplayCopyFeedback(false);
  }, [children]);

  return (
    <div>
      <div className="bg-zinc-700 text-white px-4 py-2 w-full rounded-t-md flex justify-between items-center">
        {language && (
          <Typography variant="hairline2" className="text-white capitalize">
            {language}
          </Typography>
        )}
        <Button
          startIcon={
            displayCopyFeedback ? (
              <Check style={{ height: 15 }} />
            ) : (
              <ContentCopy style={{ height: 15 }} />
            )
          }
          onClick={() => {
            if (displayCopyFeedback) return;
            handleClick();
          }}
          className="h-6 text-white hover:bg-gray-50 focus:bg-gray-50 hover:bg-opacity-20 focus:bg-opacity-40"
        >
          <Typography variant="caption" className="text-inherit font-bold">
            {displayCopyFeedback ? "Copied" : "Copy code"}
          </Typography>
        </Button>
      </div>
      <ReactSyntaxHighlighter
        showLineNumbers
        wrapLongLines
        language={language}
        style={tomorrowNightEighties}
        codeTagProps={{ className: "font-mono" }}
        customStyle={{
          marginTop: 0,
          borderRadius: 6,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          paddingBottom: 12,
        }}
      >
        {children}
      </ReactSyntaxHighlighter>
    </div>
  );
};

interface DealAssistantMessageProps {
  message: Pick<
    IAssistantMessage,
    "messageId" | "role" | "content" | "annotations" | "functionsMetadata"
  >;
  showOptions: boolean;
  onEdit?: (messageId: string, content: string) => Promise<void>;
  onDelete?: (messageId: string) => Promise<void>;
  isUserMessage: boolean;
  type?: ResourceType;
}

type AssistantMessageHeaderProps =
  | {
      isUserMessage: boolean;
      userInformation: {
        fullName?: string;
        imageUrl?: string;
        shortName?: string;
      };
    }
  | {
      isUserMessage: false;
      userInformation?: never;
    };

export const AssistantMessageHeader: FC<AssistantMessageHeaderProps> = ({
  isUserMessage,
  userInformation,
}) => {
  const layout = useSyndicationLayout();

  const logoSrc = useMemo(() => {
    if (layout.isSyndicate) {
      return layout.mainInfo.logo.logoSquare as string;
    }

    return e9Logo as unknown as string;
  }, [layout]);

  return (
    <div className="flex space-x-2 items-center mb-3">
      <Avatar
        className={clsx(
          isUserMessage ? "bg-gray-300 text-white" : "bg-primary text-white",
          "h-6 w-6 text-[1rem] font-sans !shadow-sm"
        )}
        variant={isUserMessage ? "circular" : "rounded"}
        src={isUserMessage ? userInformation.imageUrl : logoSrc}
      >
        {isUserMessage ? getNameInitials(userInformation.fullName) : null}
      </Avatar>
      <Typography variant="caption" className="font-bold text-black">
        {isUserMessage
          ? userInformation.shortName || userInformation.fullName
          : `${layout.platformShortName} AI Assistant`}
      </Typography>
    </div>
  );
};

export const AssistantMessageWrapper = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={clsxm(styles.UserMessageWrapper, className)}
      {...props}
    />
  );
});

export const DealAssistantMessage: FC<DealAssistantMessageProps> = ({
  message,
  showOptions,
  onEdit,
  onDelete,
  isUserMessage,
  type,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useErrorHandler();

  const contentRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const markdownRef = useRef<HTMLDivElement>(null);

  const [isEditing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const focus = useCallback(() => {
    const node = contentRef.current;

    if (!node) return;

    const range = document.createRange();
    const sel = window.getSelection();

    if (!sel) return;

    range.selectNodeContents(node);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
    node.focus();
  }, []);

  const actions = useMemo(() => {
    const editAction = isFunction(onEdit)
      ? [
          {
            label: "Edit",
            onClick: async () => {
              setEditing(true);
              await delayAsync(10);
              focus();
            },
          },
        ]
      : [];

    const deleteAction = isFunction(onDelete)
      ? [
          {
            onClick: () => {
              setDeleteModalOpen(true);
            },
            label: "Delete",
          },
        ]
      : [];
    return [...editAction, ...deleteAction];
  }, [focus, onDelete, onEdit]);

  const resetEdit = useCallback(() => {
    if (!contentRef.current) return;

    contentRef.current.innerText = message.content;
    setEditing(false);
  }, [message.content]);

  const handleEdit = useCallback(async () => {
    if (!onEdit) return;
    if (!contentRef.current) {
      throw new Error(
        "It was not possible to edit because the reference could not be found"
      );
    }

    setSubmitting(true);

    try {
      const content = contentRef.current.innerText;

      if (!content) {
        throw new FeedbackError("Please insert a valid value for the message.");
      }

      await onEdit(message.messageId, contentRef.current.innerText);

      setEditing(false);

      enqueueSnackbar("The message has been successfully updated.", {
        title: "Message updated",
        variant: "success",
      });
    } catch (e) {
      handleError(
        e,
        "It was not possible to edit the message. Please try again later"
      );
      resetEdit();
    } finally {
      setSubmitting(false);
    }
  }, [enqueueSnackbar, handleError, message.messageId, onEdit, resetEdit]);

  const handleDelete = useCallback(async () => {
    if (!onDelete) return;

    setSubmitting(true);

    try {
      await onDelete(message.messageId);

      enqueueSnackbar("The message has been successfully deleted.", {
        title: "Message deleted",
        variant: "success",
      });
    } catch (e) {
      handleError(
        e,
        "It was not possible to delete the message. Please try again later."
      );
    } finally {
      setSubmitting(false);
    }
  }, [enqueueSnackbar, handleError, message.messageId, onDelete]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    if (!isEditing) return;

    const content = wrapperRef.current;

    const callback: EventListener = (event) => {
      if (event.target && !content.contains(event.target as any)) {
        resetEdit();
      }
    };

    document.addEventListener("click", callback);

    return () => document.removeEventListener("click", callback);
  }, [isEditing, resetEdit]);

  const copyContent = useCallback(async (reference: HTMLElement | null) => {
    if (!reference) return;

    try {
      const html = reference.innerHTML;
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/html": new Blob([html], { type: "text/html" }),
          "text/plain": new Blob([reference.textContent || ""], {
            type: "text/plain",
          }),
        }),
      ]);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }, []);

  const functionsMetadata = useMemo(() => {
    if (!message.functionsMetadata) {
      return [];
    }
    return message.functionsMetadata.filter((m) => m.source);
  }, [message]);

  const fileCitations = useMemo(() => {
    return uniqBy(message.annotations, "fileId");
  }, [message]);

  const messageContent = useMemo(() => {
    if (message.annotations && message.annotations.length > 0) {
      return message.annotations.reduce(
        (content, current) =>
          content.replace(
            current.text,
            ` <span style="color: ${defaultBrandingColors.mainColor400}">**[${
              fileCitations.findIndex((f) => f.fileId === current.fileId) + 1
            }]**</span> `
          ),
        message.content
      );
    }
    return cleanAssistantMessage(message.content);
  }, [message, fileCitations]);

  return (
    <div className="flex w-full items-start justify-between">
      <Typography component="span" variant="caption" className="text-gray-500">
        {isUserMessage ? (
          <AssistantMessageWrapper key={message.messageId} ref={wrapperRef}>
            <>
              <div
                ref={contentRef}
                contentEditable={isEditing}
                suppressContentEditableWarning
                style={{ whiteSpace: "pre-wrap" }}
                onKeyDown={async (event: KeyboardEvent) => {
                  if (event.key === Key.Escape) {
                    resetEdit();
                  }
                }}
              >
                {message.content}
              </div>
              {isEditing && (
                <div className="w-full flex mt-2 space-x-2">
                  <IconButton
                    disabled={submitting}
                    onClick={resetEdit}
                    className="h-8 w-8"
                  >
                    <Close />
                  </IconButton>
                  <IconButton
                    disabled={submitting}
                    onClick={handleEdit}
                    className="h-8 w-8"
                  >
                    <Check />
                  </IconButton>
                </div>
              )}
            </>
          </AssistantMessageWrapper>
        ) : (
          <Markdown
            options={{
              forceWrapper: true,
              wrapper: (props) => (
                <span
                  {...props}
                  ref={markdownRef}
                  className={clsx(styles.Message)}
                />
              ),
              overrides: {
                code: MarkdownSyntaxHighlight,
              },
            }}
          >
            {messageContent}
          </Markdown>
        )}

        {fileCitations.length > 0 && (
          <div className="flex flex-wrap flex-row mt-1">
            {fileCitations.map((item, index) => (
              <FileCitation
                key={index}
                fileCitation={item}
                index={index}
                type={type}
              />
            ))}
          </div>
        )}
        {functionsMetadata.length > 0 && (
          <div className="flex flex-wrap flex-row mt-1">
            {functionsMetadata.map((item, index) => (
              <FunctionCitation key={index} functionCitation={item} />
            ))}
          </div>
        )}
      </Typography>

      <div className="pl-2 flex flex-col">
        {showOptions && actions.length > 0 && (
          <MenuButton smallItems items={actions} />
        )}
        {!isUserMessage && (
          <CopyButton
            textToCopy=""
            overrideCopyFn={() => copyContent(markdownRef.current)}
            className="sticky top-1"
          />
        )}
      </div>

      {isDeleteModalOpen && (
        <ConfirmationModal
          title="Delete template message"
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleDelete}
        >
          <Typography
            style={{ whiteSpace: "pre-wrap" }}
            variant="body1"
            className="text-gray-500"
          >
            Are you sure to delete the message?
            <br />
            <div className="p-2 mt-3 bg-gray-200 w-fit rounded-lg">
              {message.content}
            </div>
          </Typography>
        </ConfirmationModal>
      )}
    </div>
  );
};
