import { imagePlugin, MDXEditor } from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { FC } from "react";
import { defaultMarkdownInputPlugins } from "../MarkdownInput/MarkdownInput";

interface MarkdownViewerProps {
  value: string;
}

export const MarkdownViewer: FC<MarkdownViewerProps> = ({ value }) => {
  return (
    <MDXEditor
      readOnly
      plugins={[...defaultMarkdownInputPlugins, imagePlugin({})]}
      markdown={value}
    />
  );
};
