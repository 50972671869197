import {
  activeEditor$,
  AdmonitionDirectiveDescriptor,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ChangeCodeMirrorLanguage,
  codeBlockPlugin,
  codeMirrorPlugin,
  CodeToggle,
  ConditionalContents,
  CreateLink,
  currentSelection$,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  directivesPlugin,
  headingsPlugin,
  imagePlugin,
  InsertImage,
  InsertTable,
  InsertThematicBreak,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
  MDXEditorProps,
  quotePlugin,
  realmPlugin,
  Separator,
  ShowSandpackInfo,
  StrikeThroughSupSubToggles,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { captureException } from "@sentry/react";
import { basicDark } from "cm6-theme-basic-dark";
import { config } from "config";
import {
  COMMAND_PRIORITY_NORMAL,
  LexicalEditor,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { forwardRef } from "react";
import dealService from "services/deal.service";
import styles from "./MarkdownInput.styles.module.scss";

interface MarkdownInputProps extends Omit<MDXEditorProps, "markdown"> {
  value: string;
}

export const defaultMarkdownInputPlugins = [
  headingsPlugin(),
  listsPlugin(),
  quotePlugin(),
  tablePlugin(),
  thematicBreakPlugin(),
  markdownShortcutPlugin(),
  linkPlugin(),
  linkDialogPlugin(),
  codeBlockPlugin({ defaultCodeBlockLanguage: "txt" }),
  codeMirrorPlugin({
    codeBlockLanguages: {
      js: "JavaScript",
      css: "CSS",
      txt: "text",
      tsx: "TypeScript",
    },
    codeMirrorExtensions: [basicDark],
  }),
  directivesPlugin({
    directiveDescriptors: [AdmonitionDirectiveDescriptor],
  }),
  diffSourcePlugin({
    viewMode: "rich-text",
    codeMirrorExtensions: [basicDark],
  }),
];

export const highlightPlugin = realmPlugin({
  postInit: (
    realm,
    params?: {
      onSelection: (
        selection: RangeSelection | null,
        editor: LexicalEditor
      ) => void;
    }
  ) => {
    const editor = realm.getValue(activeEditor$);

    editor?.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        const selection = realm.getValue(currentSelection$);
        params?.onSelection(selection, editor);
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  },
});

export const MarkdownInput = forwardRef<MDXEditorMethods, MarkdownInputProps>(
  ({ value, readOnly, plugins = [], ...props }, ref) => {
    return (
      <MDXEditor
        {...props}
        readOnly={readOnly}
        className={styles.Container}
        plugins={[
          imagePlugin({
            imageUploadHandler: async (image: File) => {
              const formData = new FormData();

              formData.append("file", image, image.name);

              const { imageUrl } = await dealService.uploadDealImage(formData);

              return imageUrl;
            },
          }),
          toolbarPlugin({
            toolbarContents: () => (
              <DiffSourceToggleWrapper options={["rich-text", "source"]}>
                <ConditionalContents
                  options={[
                    {
                      when: (editor) => editor?.editorType === "codeblock",
                      contents: () => <ChangeCodeMirrorLanguage />,
                    },
                    {
                      when: (editor) => editor?.editorType === "sandpack",
                      contents: () => <ShowSandpackInfo />,
                    },
                    {
                      fallback: () => (
                        <>
                          <UndoRedo />

                          <Separator />

                          <BlockTypeSelect />
                          <BoldItalicUnderlineToggles />
                          <StrikeThroughSupSubToggles />
                          <CodeToggle />
                          <ListsToggle />
                          <CreateLink />

                          <Separator />

                          <InsertImage />
                          <InsertTable />
                          <InsertThematicBreak />

                          <Separator />
                        </>
                      ),
                    },
                  ]}
                />
              </DiffSourceToggleWrapper>
            ),
          }),
          ...defaultMarkdownInputPlugins,
          ...plugins,
        ]}
        onError={(error) => {
          if (["dev", "local"].includes(config.env)) {
            console.error(error);
            return;
          }

          captureException(error);
        }}
        markdown={value}
        ref={ref}
      />
    );
  }
);
