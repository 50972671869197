import { useDrag } from "@use-gesture/react";
import { ReactDOMAttributes } from "@use-gesture/react/dist/declarations/src/types";
import { defaultsDeep } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import { config, SpringValue, useSpring } from "react-spring";

export interface IUseResizableMenu {
  isCollapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
  bindDrag: (...args: any[]) => ReactDOMAttributes;
  width: SpringValue<number>;
  isDragging: boolean;
  props: any;
}

interface IUseResizableMenuOptions {
  initialBarSize: number;
  initialCollapsedState: boolean;
  minWidth: number;
  direction: "right" | "left";
}

const defaultValues: IUseResizableMenuOptions = {
  initialCollapsedState: false,
  minWidth: 325,
  initialBarSize: 528,
  direction: "right",
};

export const useResizableMenu = (
  options?: Partial<IUseResizableMenuOptions>
): IUseResizableMenu => {
  const {
    minWidth,
    initialBarSize,
    direction,
    initialCollapsedState,
  }: IUseResizableMenuOptions = defaultsDeep(options, defaultValues);

  const [isCollapsed, setCollapsed] = useState(initialCollapsedState);
  const [isDragging, setDragging] = useState(false);
  const [{ width }, api] = useSpring(() => ({
    width: initialBarSize,
    config: { ...config.stiff },
  }));

  const bindDrag = useDrag(
    ({ offset, lastOffset, movement, dragging }) => {
      setDragging(dragging || false);

      const width =
        direction === "right" ? offset[0] : lastOffset[0] - movement[0];

      api.set({
        width: Math.max(width, minWidth),
      });
    },
    {
      from: [Math.max(width.get(), minWidth), 0],
      axis: "x",
    }
  );

  const props = useSpring({
    width: isCollapsed ? 0 : width,
    overflow: isCollapsed ? "hidden" : "",
  });

  return {
    isCollapsed,
    setCollapsed,
    bindDrag,
    width,
    isDragging,
    props,
  };
};
