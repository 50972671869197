import { MenuItem, MenuList, Paper } from "@mui/material";
import { useRef } from "react";
import { PluginProps } from "react-markdown-editor-lite";
import {
  MarkdownInputInsertTextPluginProps,
  MarkdownInputProps,
} from "./MarkdownInput.legacy";

const MarkdownInputInsertTextPlugin = (props: PluginProps) => {
  const ref = useRef<HTMLSpanElement>(null);
  const config = props.editor.props.config as MarkdownInputProps["config"];
  const { label, options = [] } = (config?.insertText ||
    {}) as MarkdownInputInsertTextPluginProps;

  const handleClick = (option: Option) => {
    props.editor.insertText(option.value, true, {
      start: 0,
      end: option.value.length,
    });
  };

  if (!config?.insertText) return null;

  return (
    <span
      ref={ref}
      className="button group !flex !items-center !justify-center"
    >
      {label}

      <Paper className="absolute top-[calc(100%_-_2px)] left-0 hidden hover:!block group-hover:block z-10">
        <MenuList>
          {options.map((option) => (
            <MenuItem
              className="!p-3"
              key={option.value}
              onClick={() => handleClick(option)}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
    </span>
  );
};

const MARKDOWN_INPUT_INSERT_LOGO_PLUGIN_NAME = "MARKDOWN_INPUT_INSERT_LOGO";

MarkdownInputInsertTextPlugin.defaultConfig = {};
MarkdownInputInsertTextPlugin.align = "left";
MarkdownInputInsertTextPlugin.pluginName =
  MARKDOWN_INPUT_INSERT_LOGO_PLUGIN_NAME;

export { MarkdownInputInsertTextPlugin as MarkdownInputInsertLogoPlugin };
