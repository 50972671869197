import { MoreHoriz, MoreVert } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  MenuItem,
  PopoverOrigin,
} from "@mui/material";
import { clsxm } from "application/utils";
import clsx from "clsx";
import { noop } from "lodash";
import { FC, useEffect, useState } from "react";
import { SyntheticEvent } from "react-draft-wysiwyg";
import { StyledMenu } from "../StyledMenu";

export interface MenuButtonItem {
  onClick?: (e: SyntheticEvent) => void | Promise<void>;
  label: string;
  icon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

export interface MenuButtonProps {
  items: MenuButtonItem[];
  outline?: boolean;
  size?: "small" | "medium" | "large";
  type?: "circular" | "rounded";
  smallItems?: boolean;
  className?: string;
  boldItems?: boolean;
  transformOrigin?: PopoverOrigin;
  loading?: boolean;
  disabled?: boolean;
  iconVertical?: boolean;
}

export const MenuButton: FC<MenuButtonProps> = ({
  items,
  outline,
  size = "medium",
  className,
  boldItems,
  loading,
  transformOrigin,
  smallItems,
  disabled,
  iconVertical,
  type = "circular",
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (loading || disabled) {
      handleClose();
    }
  }, [disabled, loading]);

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        ref={(ref) => {
          if (!ref) return;

          ref.onclick = (e) => {
            handleClick(e as any);
          };
        }}
        className={clsxm(
          "flex justify-center items-center relative",
          className,
          {
            "h-8 w-8": size === "small",
            "h-10 w-10": size === "medium",
            "h-12 w-12": size === "large",
            "border-2 border-solid border-gray-200": outline,
            "bg-gray-150 text-primary": open,
            "rounded-xl": type === "rounded",
          }
        )}
        disabled={loading || disabled}
      >
        {loading ? (
          <div className="w-full h-full flex justify-center items-center">
            <CircularProgress size={16} />
          </div>
        ) : iconVertical ? (
          <MoreVert />
        ) : (
          <MoreHoriz />
        )}
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={(e: SyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();

          handleClose();
        }}
        transformOrigin={transformOrigin}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items.map(
          (
            { label, icon, onClick = noop, loading, disabled, className },
            index
          ) => (
            <MenuItem
              key={index}
              disabled={loading || disabled}
              className={clsx(
                smallItems ? "text-[0.675rem]" : "text-[0.875rem]",
                boldItems && "font-bold",
                className
              )}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await onClick(e);
                handleClose();
              }}
            >
              {icon && (
                <ListItemIcon>
                  {loading ? <CircularProgress size={16} /> : icon}
                </ListItemIcon>
              )}
              {label}
            </MenuItem>
          )
        )}
      </StyledMenu>
    </>
  );
};
