import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import { FormInputWrapper, FormInputWrapperProps } from "../FormInputWrapper";
import {
  LegacyMarkdownInput,
  MarkdownInputProps,
} from "./MarkdownInput.legacy";

interface MarkdownInputControllerProps<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<MarkdownInputProps, "onChange" | "value">,
    Omit<FormInputWrapperProps, "errorMessage"> {}

export const MarkdownInputController = <T extends FieldValues>({
  control,
  label,
  name,
  allowCollapse,
  labelAction,
  helperText,
  tooltip,
  collapseInitialState,
  className,
  ...props
}: MarkdownInputControllerProps<T>) => {
  const { field, fieldState } = useController({ control, name });
  return (
    <FormInputWrapper
      allowCollapse={allowCollapse}
      collapseInitialState={collapseInitialState}
      className={className}
      label={label}
      errorMessage={fieldState.error?.message}
    >
      <LegacyMarkdownInput {...field} {...props} />
    </FormInputWrapper>
  );
};
